import { domReady, initOnIntersection } from 'utilities/dom';
import {
	checkIfVideoAPIReady, videoOptions, VideoPlayer, injectYouTubeIframeAPI
} from 'features/youtube';
import { utagLinkChat } from 'features/tealium';
import relatedVideos from 'features/relatedVideos/desktop';
import { openMessenger } from 'features/genesysCloud';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import dynamicImport from '../../_client/features/webpack/dynamicImport.macro';

// on doc ready
domReady(() => {

	const trackEventChat = utagLinkChat();

	// init youtube video

	const proLandingPromoVideo = (function () {

		let player;
		let iframeAPIinjected = false;

		const { apiSettings, aspectRatio } = videoOptions;

		const ui = {
			proLandingVideoThumb: document.getElementById('proLandingVideo')
		};

		const utils = {
			getDynamicVideoWidth () {

				return document.documentElement.clientWidth <= 1000 ? 700 : 900;

			},
			getDynamicVideoHeight () {

				const screenHeight = document.documentElement.clientHeight;

				if (screenHeight <= 600) {

					return 500;

				}
				if (screenHeight <= 800) {

					return 600;

				}
				if (screenHeight <= 1000) {

					return 700;

				}
				return 900;

			},
			getPlayerDimensions () {

				return {
					width: utils.getDynamicVideoWidth(),
					height: utils.getDynamicVideoHeight() * aspectRatio
				};

			},
			getModalVideoContent () {

				return '<div id="player"></div>';

			}
		};

		const handlers = {
			videoThumbnailClick: (e) => {

				const videoId = e.currentTarget.dataset.videoid;

				const { width, height } = utils.getPlayerDimensions();

				if (videoId) {

					$('body').lpModal({
						onInit: true,
						lpModalClass: 'lpModal--alt1 lpModal--noScroll',
						content: $(utils.getModalVideoContent()),
						width,
						height,
						openEvt: () => {

							if (!iframeAPIinjected) {

								injectYouTubeIframeAPI();
								iframeAPIinjected = true;

							}

							checkIfVideoAPIReady().then(() => {

								// initialize player when modal opens
								player = VideoPlayer('player', {
									width,
									height,
									videoId,
									playerVars: apiSettings
								});

							});

						},
						closeEvt: () => {

							// destroy player when modal closes
							player.destroy();
							player = null;

						}
					});

				}

			}
		};

		function init () {

			if (ui.proLandingVideoThumb) {

				ui.proLandingVideoThumb.addEventListener('click', handlers.videoThumbnailClick);

			}

		}

		return {
			init
		};

	}());

	proLandingPromoVideo.init();

	const { globals } = window.lp;

	if (globals) {

		const pixleeElement = document.getElementById('pixleeProsWidget');
		const marker = document.getElementById('pixleeWidgetProsScrollMarker');
		const pixleeWidgetShowNextPrevButtonProsPageToggleOn = JSON.parse(
			window.lp.globals.pixleeWidgetShowNextPrevButtonProsLandingPagePageToggleOn
		);

		if (pixleeElement) {

			if (window.isIpad()) {

				// container width for iPad needs to be handled separately
				const pixleeContainer = document.querySelector('.instagramFeed--4Across.pixleeContainer');
				pixleeContainer?.classList.add('iPadWidth');

			}
			initOnIntersection(
				marker,
				() => {

					dynamicImport('desktop/pros-pixleeInstagramFeed', './pixleeInstagramFeed').then((module) => {

						const initProsInstagramFeed = module.default;
						initProsInstagramFeed(pixleeElement, pixleeWidgetShowNextPrevButtonProsPageToggleOn);

					});

				},
				'0px 0px 500px 0px'
			);

		}

	}

	if (window.lp.globals.canShowCertona) {

		window.lp.certona.getRecommendedItems();

	}

	// initiate related video widget if we have data for it
	if (document.getElementById('relatedVideosSection')) {

		injectYouTubeIframeAPI();
		relatedVideos().init();

	}

	const chatButton = document.querySelector('.chatButtonContainer button');
	chatButton?.addEventListener('click', (el) => {

		openMessenger();
		const eventLabel = el.currentTarget.closest('[data-ga-label]')?.dataset.gaLabel || '';

		const buttonText = el.currentTarget.innerText;

		trackEventChat({
			eventAction: 'Request-Chat',
			eventLabel
		});

		setGenericEvent({
			event_name: 'chat_click',
			chat_button_type: 'static',
			chat_button_text: buttonText
		});

	});

});
