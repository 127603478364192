import utagLinkGeneric from './utagLinkGeneric';

function utagLinkLanding (eventCategory = 'Landing') {

	return ({ eventAction, eventLabel = '', nonInteraction = 0 }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkLanding;
