import { debounce } from 'lodash-es';

import LPGlider from 'ui/glider';
import {
	sendVideoAnalytics,
	trackVideoDismiss,
	trackVideoPlayback,
	trackVideoThumbnailClick,
	utagLinkPDP,
	utagLinkLanding
} from 'features/tealium';
import { setVideoLoad } from 'features/tealium/ga4/custom';
import {
	checkIfVideoAPIReady, extractVideoIdFromUrl, PlayerState, videoOptions, VideoPlayer
} from 'features/youtube';
import unveil from 'ui/lazy-load/unveil';

import helpers from './helpers';

const INITIAL_SLIDE_INDEX = 0;
let resizeHandlerSet = false;

const initGlider = (element, options, initialSlide = INITIAL_SLIDE_INDEX) => {

	if (!element) {

		return null;

	}

	element.addEventListener('glider-loaded', function () {

		const glider = LPGlider(this);
		glider.setActiveSlide(initialSlide);
		this.classList.remove('glider-loading');

	});

	element.addEventListener('glider-slide-visible', function () {

		const veiledImages = this.querySelectorAll('.unveil.notInView');
		if (veiledImages.length > 0) {

			unveil(veiledImages);

		}

	});

	return new LPGlider(element, options);

};
const relatedVideos = () => {

	const ui = {
		pdRelatedVideos: document.getElementById('pdRelatedVideos'),
		gliderContainer: document.querySelector('#pdRelatedVideos .glider-container'),
		relatedVideoItems: document.querySelectorAll('#relatedVideosSection .js-related-videos-item'),
		relatedVideoThumbnailInModal: document.querySelectorAll(
			'#relatedVideosSectionInModal .js-related-videos-thumbnail'
		)
	};

	const showRelatedVideoModal = (e) => {

		e.preventDefault();

		let initialSlideIndex = INITIAL_SLIDE_INDEX;
		const videoThumbnailEl = e.currentTarget.querySelector('.js-related-videos-thumbnail');
		const { pdRelatedVideos } = ui;

		const { videoId } = videoThumbnailEl.dataset;
		const { aspectRatio } = videoOptions;
		const initialDisplayMode =			document.documentElement.clientWidth > document.documentElement.clientHeight ? 'landscape' : 'portrait';
		const videoPlayerDimensions = {
			width: window.isIpad() && initialDisplayMode === 'landscape' ? 768 : helpers.getDynamicVideoWidth(),
			height:
				window.isIpad() && initialDisplayMode === 'landscape'
					? 680 * aspectRatio
					: helpers.getDynamicVideoHeight() * aspectRatio
		};
		const relatedVideosContent = helpers.getRelatedVideosContent();
		const relatedVideoContentForModal = relatedVideosContent.length > 1 ? relatedVideosContent.join('') : '';
		const pdRelatedVideosModalHeight = relatedVideoContentForModal !== '' ? $(pdRelatedVideos).height() : 0;
		const relatedVideosModalTemplate =			relatedVideoContentForModal !== ''
			? `
				<div id="player"></div>
				<div id="relatedVideosSectionInModal">
					<div class="related-videos-wrapper">
						<button class="glider-prev glider-arrow" type="button" aria-label="Previous"></button>
						<div id="pdModalRelatedVideos" class="related-videos">
							${relatedVideoContentForModal}
						</div>
						<button class="glider-next glider-arrow" type="button" aria-label="Next"></button>
					</div>
				</div>
			`
			: '<div id="player"></div>';
		let videoPlayer;

		const videoIndex = relatedVideosContent.findIndex((el) => el.includes(videoId));
		initialSlideIndex = videoIndex >= 0 ? videoIndex : INITIAL_SLIDE_INDEX;
		window.lp.video.activeVideoHeadline = helpers.getHeadlinefromRelatedItem(videoThumbnailEl);

		trackVideoThumbnailClick(videoId, {
			fromModal: false
		});

		const setOrientationChangeEvent = () => {

			window.addEventListener(
				'orientationchange',
				() => {

					const currentDisplayMode =						document.documentElement.clientWidth > document.documentElement.clientHeight
						? 'landscape'
						: 'portrait';
					const lpRelatedVideosModal = document.querySelector('.lpModalRelatedVideos');

					if (lpRelatedVideosModal) {

						lpRelatedVideosModal.style.visibility = 'hidden';
						window.setTimeout(() => {

							try {

								lpRelatedVideosModal.style.top =									currentDisplayMode === 'portrait'
									? `${window.screen.height - lpRelatedVideosModal.height()}px`
									: '22px';
								lpRelatedVideosModal.style.visibility = 'visible';

							} catch (error) {

								lpRelatedVideosModal.top = '22px';
								lpRelatedVideosModal.visibility = 'visible';

							}

						}, 300);

					}

				},
				false
			);

		};

		const renderVideoCarousel = (videoPlayer) => {

			const pdRelatedVideosInModal = document.getElementById('pdModalRelatedVideos');

			if (!pdRelatedVideosInModal) {

				return;

			}

			const gliderOptions = {
				slidesToShow: 5,
				slidesToScroll: 5,
				dots: false,
				rewind: false,
				duration: 0,
				arrows: {
					prev: '#relatedVideosSectionInModal .glider-prev',
					next: '#relatedVideosSectionInModal .glider-next'
				}
			};

			initGlider(pdRelatedVideosInModal, gliderOptions, initialSlideIndex);

			const relatedVideosWrapper = document.querySelector('#relatedVideosSectionInModal .related-videos-wrapper');

			helpers.adjustGliderArrowTopPosition(relatedVideosWrapper);

			if (!resizeHandlerSet) {

				window.addEventListener(
					'resize',
					debounce(() => helpers.adjustGliderArrowTopPosition(relatedVideosWrapper), 30)
				);

				resizeHandlerSet = true;

			}

			// show and play clicked video from modal
			const relatedVideoItemsInModal =				pdRelatedVideosInModal.querySelectorAll('.js-related-videos-item');

			relatedVideoItemsInModal.forEach((relatedVideo) => {

				relatedVideo.addEventListener('click', (e) => {

					e.preventDefault();
					const modalVideoThumbnailEl = e.currentTarget.querySelector('.js-related-videos-thumbnail');
					const { videoId: modalVideoId } = modalVideoThumbnailEl.dataset;
					const playerState = videoPlayer.getPlayerState();

					// check if video is still playing after clicking on different thumbnail
					if (playerState === PlayerState.PLAYING) {

						// capture pause event on playing video and send headline of the video being paused
						// also capture video id of previously playing video
						const skippedVideoId = extractVideoIdFromUrl(videoPlayer.getVideoUrl());
						sendVideoAnalytics({
							eventAction: `Pause-${window.location.pathname}`,
							eventLabel: `${skippedVideoId}-${window.lp.video.activeVideoHeadline}`,
							eventValue: Math.round(videoPlayer.getCurrentTime()).toString()
						});

					}

					window.lp.video.activeVideoHeadline = helpers.getHeadlinefromRelatedItem(modalVideoThumbnailEl);
					trackVideoThumbnailClick(modalVideoId, { fromModal: true });
					videoPlayer.loadVideoById(modalVideoId);

				});

				relatedVideo.parentElement.addEventListener('keyup', (e) => {

					const { target } = e;
					const image = target.querySelector('img');
					e.preventDefault();

					if (e.keyCode === 13 || e.keyCode === 32) {

						image.click();

					}

				});

			});

		};

		const renderVideosInModal = () => {

			// iPad orientation change fix
			if (window.isIpad()) {

				setOrientationChangeEvent();

			}

			// grab html for related videos from PDP to use in the modal
			const relatedVideoElements = pdRelatedVideos
				? pdRelatedVideos.querySelectorAll('.js-related-videos-item')
				: [];
			// overwrite default video api settings (if needed) here
			const videoApiSettings = { ...videoOptions.apiSettings };

			checkIfVideoAPIReady().then(() => {

				videoPlayer = VideoPlayer('player', {
					width: videoPlayerDimensions.width,
					height: videoPlayerDimensions.height,
					videoId,
					playerVars: videoApiSettings,
					events: {
						onReady () {

							videoPlayer.playVideo();
							trackVideoPlayback(videoPlayer, { id: videoId });

						}
					}
				});

				// only initialize carousel for more than one video.
				if (relatedVideoElements.length > 1) {

					renderVideoCarousel(videoPlayer);

				}

			});

		};

		$('body').lpModal({
			onInit: true,
			lpModalClass: 'lpModal--alt1 lpModalRelatedVideos',
			content: $(relatedVideosModalTemplate),
			width: videoPlayerDimensions.width,
			height:
				pdRelatedVideosModalHeight > 0
					? videoPlayerDimensions.height + 50 + parseFloat(pdRelatedVideosModalHeight)
					: videoPlayerDimensions.height,
			openEvt: renderVideosInModal,
			closeEvt: () => {

				trackVideoDismiss(videoPlayer)
					.then((trackingObj) => {

						sendVideoAnalytics(trackingObj);
						videoPlayer.destroy();

					})
					.catch((err) => {

						videoPlayer.destroy();
						console.error(err.message);

					});

			}
		});

	};

	const relatedVideoGAEvents = () => {

		const { page_type } = window.utag_data;
		if (page_type === 'product') {

			utagLinkPDP()({
				eventAction: 'Load-Related-Video',
				eventLabel: window.shortSku,
				nonInteraction: true
			});

		} else if (page_type === 'all') {

			sendVideoAnalytics({
				eventAction: 'Load-Related-Video',
				eventCategory: 'Pros',
				eventLabel: window.location.pathname
			});

		} else if (page_type === 'landing') {

			utagLinkLanding()({
				eventAction: 'Load-Related-Video',
				eventLabel: window.location.pathname,
				nonInteraction: true
			});

			setVideoLoad({
				pageType: window.utag_data.page_type,
				section: 'related videos'
			});

		}

	};

	function init () {

		const { relatedVideoItems, gliderContainer } = ui;

		// assume a page is Responsive if the lpContainer div has max-width set
		const lpContainer = document.getElementById('lpContainer');
		const relatedVideoAsMyml = document.querySelector('.relatedVideoAsMyml');
		const isSemiResponsivePage = lpContainer && window.getComputedStyle(lpContainer).maxWidth !== 'none';
		const slideCount = isSemiResponsivePage && !relatedVideoAsMyml ? 7 : 5;

		const gliderOptions = {
			slidesToShow: slideCount,
			slidesToScroll: slideCount,
			dots: false,
			rewind: false,
			duration: 0.3,
			arrows: {
				prev: '#pdRelatedVideos .glider-prev',
				next: '#pdRelatedVideos .glider-next'
			}
		};

		initGlider(gliderContainer, gliderOptions);

		if (relatedVideoItems.length) {

			relatedVideoItems.forEach((item) => {

				item.addEventListener('click', showRelatedVideoModal);

			});

			relatedVideoGAEvents();

		}

	}

	return Object.freeze({ init });

};

export default relatedVideos;
