import { extractVideoIdFromUrl } from 'features/youtube';

import utagLinkGeneric from './utagLinkGeneric';

function sendVideoAnalytics ({
	eventAction, eventCategory = 'Video', eventLabel = '', eventValue = ''
}) {

	utagLinkGeneric({
		eventCategory,
		eventAction,
		eventLabel,
		eventValue
	});

}

function buildUrlPath () {

	const urlPath = window.location.pathname;
	const urlParams = new URLSearchParams(window.location.search);
	const sfpParam = urlParams.get('sfp');

	if (!sfpParam) return urlPath;
	return `${urlPath}?sfp=${sfpParam}`;

}

function trackVideoDismiss (player) {

	return new Promise((resolve, reject) => {

		try {

			clearInterval(window.lp.videoPlaybackInterval);
			const pageUrl = buildUrlPath();
			const playerFullyInitialized =				player
				&& player.getVideoUrl !== undefined
				&& player.getPlayerState !== undefined
				&& player.getCurrentTime !== undefined;
			if (playerFullyInitialized) {

				const videoId = extractVideoIdFromUrl(player.getVideoUrl());
				const { activeVideoHeadline } = window.lp.video;

				// fire only if the video is not finished
				if (videoId && player.getPlayerState() !== 0) {

					return resolve({
						eventAction: `Stop-${pageUrl}`,
						eventLabel: `${videoId}-${activeVideoHeadline}`,
						eventValue: Math.round(player.getCurrentTime()).toString()
					});

				}

				// no data to resolve here, but we should pass that to caller
				return resolve(null);

			}

			return resolve(null);

		} catch (error) {

			return reject(error);

		}

	});

}

function trackVideoPlayback (player, settings = {}) {

	try {

		const defaultSettings = {
			id: ''
		};
		const { id } = { ...defaultSettings, ...settings };
		const { PlayerState } = window.YT;
		const pageUrl = buildUrlPath();
		const playerFullyInitialized =			player
			&& player.getVideoUrl !== undefined
			&& player.getPlayerState !== undefined
			&& player.getCurrentTime !== undefined;
		let lastState;

		if (playerFullyInitialized) {

			player.addEventListener('onStateChange', (e) => {

				const videoId = id || extractVideoIdFromUrl(player.getVideoUrl());
				const state = e.data;
				const videoPlayTime = Math.round(player.getCurrentTime());
				const { activeVideoHeadline } = window.lp.video;
				const eventLabel = `${videoId}-${activeVideoHeadline}`;

				if (state === PlayerState.PLAYING && lastState !== 0) {

					if (lastState != PlayerState.PAUSED) {

						sendVideoAnalytics({
							eventAction: `Play-${pageUrl}`,
							eventLabel
						});

					}

					clearInterval(window.lp.videoPlaybackInterval);
					window.lp.videoPlaybackInterval = setInterval(() => {

						const currentPlayTime = Math.round(player.getCurrentTime());

						if (currentPlayTime === 15) {

							sendVideoAnalytics({
								eventAction: `Play-15-${pageUrl}`,
								eventLabel,
								eventValue: '15'
							});

						}

						if (currentPlayTime === 30) {

							sendVideoAnalytics({
								eventAction: `Play-30-${pageUrl}`,
								eventLabel,
								eventValue: '30'
							});

						}

					}, 1000);

				} else {

					clearInterval(window.lp.videoPlaybackInterval);

				}

				if (state === PlayerState.PLAYING && lastState === PlayerState.PAUSED) {

					sendVideoAnalytics({
						eventAction: `Play-${pageUrl}`,
						eventLabel,
						eventValue: videoPlayTime.toString()
					});

				}

				if (state === PlayerState.PAUSED && videoPlayTime > 0) {

					sendVideoAnalytics({
						eventAction: `Pause-${pageUrl}`,
						eventLabel,
						eventValue: videoPlayTime.toString()
					});

				}

				// video ended
				if (state === PlayerState.ENDED) {

					sendVideoAnalytics({
						eventAction: `Play-All-${pageUrl}`,
						eventLabel,
						eventValue: videoPlayTime.toString()
					});

				}

				lastState = state;

			});

		}

	} catch (error) {

		console.error(error);

	}

}

function trackVideoThumbnailClick (videoid, settings) {

	try {

		const defaultSettings = {
			fromModal: false
		};
		const { fromModal, videoHeadline } = { ...defaultSettings, ...settings };
		const { activeVideoHeadline } = window.lp.video;
		const pageUrl = buildUrlPath();
		const eventAction = `Click On ${fromModal ? 'Modal' : 'Page'}-${pageUrl}`;
		sendVideoAnalytics({
			eventAction,
			eventLabel: `${videoid}-${videoHeadline || activeVideoHeadline}`
		});

	} catch (error) {

		console.error(error);

	}

}

export {
	sendVideoAnalytics, trackVideoDismiss, trackVideoPlayback, trackVideoThumbnailClick
};
