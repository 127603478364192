function injectYouTubeIframeAPI () {

	const src = 'https://www.youtube.com/iframe_api';
	const exists = document.querySelector(`[src="${src}"]`);

	if (!exists) {

		const tag = document.createElement('script');
		tag.src = src;
		document.head.append(tag);

	} else {

		console.warn(`${src} script already exists.`);

	}

	// Note: checkIfVideoAPIReady is used with YouTube callback onYouTubeIframeAPIReady

}

export default injectYouTubeIframeAPI;
