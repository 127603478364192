/*
-1 - unstarted
0 - ended
1 - playing
2 - paused
3 - buffering
5 - video cued
	https://developers.google.com/youtube/iframe_api_reference#Playback_status
*/
const PlayerState = {
	UNSTARTED: -1,
	ENDED: 0,
	PLAYING: 1,
	PAUSED: 2,
	BUFFERING: 3,
	CUED: 5
};

export default PlayerState;
