import { getQueryStringParameters } from 'utilities/browser';

function extractVideoIdFromUrl (url) {

	const QUERY_PARAM = 'v';
	if (!url || url.indexOf('?') < 0) {

		return '';

	}
	return getQueryStringParameters(url.split('?')[1])[QUERY_PARAM] || '';

}

export default extractVideoIdFromUrl;
