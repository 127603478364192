const helpers = {
	getRelatedVideosContent () {

		const pdRelatedVideos = document.getElementById('pdRelatedVideos');

		const relatedVideos = pdRelatedVideos.querySelectorAll('.js-related-videos-item');
		const arrayOfVideoElements = [];

		relatedVideos.forEach((rv) => {

			const elementContent = rv.innerHTML;
			const { videoid } = rv.dataset;
			arrayOfVideoElements.push(
				`<button type="button" class="anchorLink js-related-videos-item related-videos__item related-videos__item--inside-modal"
					data-videoid="${videoid}" data-location="related">${elementContent}</button>`
			);

		});

		return arrayOfVideoElements;

	},
	adjustGliderArrowTopPosition (relatedVideosWrapper) {

		const relatedVideoThumbnail = relatedVideosWrapper?.querySelector('.js-related-videos-item img');
		if (relatedVideoThumbnail) {

			// vertically center glider arrows with respect to the thumbnail (excluding product name)
			relatedVideosWrapper.style.setProperty('--gliderArrowTop', `${relatedVideoThumbnail.offsetHeight / 2}px`);

		}

	},
	getDynamicVideoHeight () {

		const screenHeight = document.documentElement.clientHeight;

		if (screenHeight <= 600) {

			return 500;

		}
		if (screenHeight <= 800) {

			return 600;

		}
		if (screenHeight <= 1000) {

			return 700;

		}

		return 900;

	},

	getDynamicVideoWidth () {

		return document.documentElement.clientWidth <= 1000 ? 700 : 900;

	},

	getHeadlinefromRelatedItem (element) {

		const headlineElement =			element.closest('.js-related-videos-item') === element
			? element.querySelector('.video-info__headline')
			: element.closest('.js-related-videos-item').querySelector('.video-info__headline');
		const headlineText = headlineElement ? headlineElement.textContent.trim() : '';
		return headlineText;

	}
};

export default helpers;
